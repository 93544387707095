@import '../base/mixins';
@import '../theme/colors';
@import '../theme/form-mixins';
@import '../base/typography';

$mc4wp-success-color: $color-success !default;
$mc4wp-notice-color: $color-notice !default;
$mc4wp-error-color: $color-error !default;
$mc4wp-submit-color: $color-form-submit !default;

@if import-once('components/_mc4wp-form') {
  /* components/_mc4wp-form.scss */

  .mc4wp-form {
    @include margin($bottom: true, $multiplier: 2.0);

    .fields {
      @include clearfix();
      @include margin($top: true);

      @include respond-to('large+') {
        max-width: 450px;
      }

      > input.email {
        @include form-input-text();

        width: calc(100% - (100px + #{$default-margin-sz}));
        float: left;
      }

      > input.submit {
        @include form-button();

        float: right;
        color: $color-white;
        background-color: $mc4wp-submit-color;

        &:hover {
          color: $color-black;
          background-color: $color-white;
        }
      }
    }

    .mc4wp-alert {
      @include margin($top: true, $bottom: true);
      @include padding-all();
      @include typo-aa-subpixel();

      font-weight: 400;
      text-align: center;
      color: $color-white;

      strong,
      b {
        font-weight: bold;
      }

      &.mc4wp-success {
        background-color: $mc4wp-success-color;
      }

      &.mc4wp-notice {
        background-color: $mc4wp-notice-color;
      }

      &.mc4wp-error {
        background-color: $mc4wp-error-color;
      }
    }
  }
}