@import 'colors';

$default-margin-sz: 15px !default;
$unordered-list-symbol: '\2013' !default;
$list-symbol-color: $color-grey !default;
$list-clear: none !default;
$list-float: none !default;

@mixin padding($top: false, $right: false, $bottom: false, $left: false,
$multiplier: 1.0, $size: $default-margin-sz) {
  @if $top == true {
    padding-top: $size * $multiplier;
  }
  @if $right == true {
    padding-right: $size * $multiplier;
  }
  @if $bottom == true {
    padding-bottom: $size * $multiplier;
  }
  @if $left == true {
    padding-left: $size * $multiplier;
  }
}

@mixin padding-all($multiplier: 1.0, $size: $default-margin-sz) {
  @include padding(true, true, true, true, $multiplier, $size);
}

@mixin margin($top: false, $right: false, $bottom: false, $left: false,
$multiplier: 1.0, $size: $default-margin-sz) {
  @if $top == true {
    margin-top: $size * $multiplier;
  }
  @if $right == true {
    margin-right: $size * $multiplier;
  }
  @if $bottom == true {
    margin-bottom: $size * $multiplier;
  }
  @if $left == true {
    margin-left: $size * $multiplier;
  }
}

@mixin margin-all($multiplier: 1.0, $size: $default-margin-sz) {
  @include margin(true, true, true, true, $multiplier, $size);
}

@mixin nomp($no-margin: true, $no-padding: true) {
  @if $no-margin == true {
    margin: 0;
  }
  @if $no-padding == true {
    padding: 0;
  }
}

@mixin anchor($color: $color-default, $hover-color: $color-black) {
  color: $color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $hover-color;
  }
}

@mixin img-autosize() {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
}

@mixin img-vignette($size: 50px, $inset: -15px, $strength: 0.25) {
  box-shadow: inset 0 0 $size $inset rgba(0, 0, 0, $strength);
}

@mixin shadow($size: 15px, $strength: 0.25, $inset: true) {
  @if $inset == true {
    box-shadow: inset 0 $size $size ($size * -1.0) rgba(0, 0, 0, $strength);
  } @else {
    box-shadow: 0 $size $size ($size * -1.0) rgba(0, 0, 0, $strength);
  }
}

@mixin shadow-none() {
  box-shadow: none;
}

@mixin clearfix() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin unordered-list($recurse: true, $float: $list-float, $nested: false, $clear: $list-clear) {
  @include nomp();

  max-width: 100%;
  list-style: none;
  float: $float;
  clear: $clear;

  @if $nested == false {
    @include margin($top: true, $bottom: true, $multiplier: 0.5);
  }

  > li {
    @include debug-outline($color: green);

    padding-left: 1.9em;
    text-indent: -1.85em;

    &:before {
      @include typo-bold();
      @include debug-outline($color: cyan);
      font-size: 80%;
      content: $unordered-list-symbol;
      counter-increment: item;
      color: $list-symbol-color;
      margin-right: 0.5em;
      display: inline-block;
      width: 1.75em;
      text-align: right;
    }

    > div,
    > p {
      @include nomp();
      display: inline;
    }
  }

  @if $recurse == true {
    @include debug-outline($color: red);
    //noinspection SassScssUnresolvedMixin
    @include __recurse-list();
  } @else {
    @include debug-outline($color: magenta);
  }
}

@mixin ordered-list($recurse: true, $float: $list-float, $nested: false, $clear: $list-clear) {
  @include nomp();

  max-width: 100%;
  list-style: none !important;
  counter-reset: item;
  float: $float;
  clear: $clear;

  @if $nested == false {
    @include margin($top: true, $bottom: true, $multiplier: 0.5);
  }

  > li {
    @include debug-outline($color: green);

    padding-left: 1.9em;
    text-indent: -1.85em;

    &:before {
      @include typo-bold();
      @include debug-outline($color: cyan);
      font-size: 80%;
      content: counter(item) '.';
      counter-increment: item;
      color: $list-symbol-color;
      margin-right: 0.5em;
      display: inline-block;
      width: 1.75em;
      text-align: right;
    }

    > div,
    > p {
      @include nomp();
      display: inline;
    }
  }

  @if $recurse == true {
    @include debug-outline($color: red);
    //noinspection SassScssUnresolvedMixin
    @include __recurse-list();
  } @else {
    @include debug-outline($color: magenta);
  }
}

@mixin __recurse-list() {
  ul:not(.-custom) {
    @include unordered-list(false, none, true);
  }
  ol:not(.-custom) {
    @include ordered-list(false, none, true);
  }
}

@mixin lists($ol: true, $ul: true, $selector: '') {
  @if $ol == true {
    #{$selector} ol:not(.-custom) {
      @include ordered-list();
      @content;
    }
  }
  @if $ul == true {
    #{$selector} ul:not(.-custom) {
      @include unordered-list();
      @content;
    }
  }
}

@mixin tag-anchor() {
  display: inline-block;
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  border: 1px solid $color-light-grey;
  margin-left: 4px;
  color: $color-default;
  @content;

  &:hover {
    border: 1px solid $color-default;
    background-color: $color-default;
    color: $color-white;
    text-decoration: none;
  }
}

@mixin respond-to-content($bkp: false) {
  @if not $bkp {
    @content;
  } @else {
    @include respond-to($bkp) {
      @content;
    }
  }
}

@mixin transition($properties) {
  $result: ();
  @each $property in $properties {
     $result: append($result, $property $transition-duration ease, 'comma')
  }

  transition: $result;
}

@if import-once('base/_mixins') {
  /* base/_mixins.scss */
}