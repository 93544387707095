@import '../base/colors';
@import '../fonts/ladislav';

$typo-text-color: $color-default !default;
$typo-line-height: 1.5 !default;
$typo-condensed-lspacing: -0.5px !default;

$typo-default-font: 'Arial', sans-serif !default;

@mixin typo-bold($weight: 600, $letter-spacing: $typo-condensed-lspacing) {
  font-weight: $weight;
  letter-spacing: $letter-spacing;
}

@mixin typo-default() {
  line-height: $typo-line-height;

  a {
    color: $color-default;
    text-decoration: none;
    letter-spacing: $typo-condensed-lspacing;

    &:hover {
      color: $color-black;
      text-decoration: underline;
    }
  }

  b, strong, em {
    @include typo-bold(700);
  }
}

@mixin typo-aa-subpixel() {
  -webkit-font-smoothing: subpixel-antialiased;
}

@if import-once('base/typography') {
  /* base/_typography.scss */

  body {
    font-family: $typo-default-font;
    font-size: 16px;
    color: $typo-text-color;
  }
}