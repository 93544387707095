@import '../base/colors';
@import '../base/mixins';
@import '../theme/typography';
@import '../theme/colors';

@if import-once('components/_home-menu') {
  /* components/_home-menu.scss */

  nav.home-menu {
    position: absolute;
    bottom: 16vh;
    width: 100%;

    @include respond-to('msmall-') {
      @include respond-to('landscape') {
        position: relative;
        bottom: auto;
      }
    }

    a.menu-item {
      @include nomp();
      @include padding-all();
      @include transition(color background-color);

      display: block;
      font-family: $theme-heading-font;
      font-weight: 400 !important;
      font-size: 1.75em;
      text-transform: uppercase;
      color: $color-theme-grey !important;

      &:hover {
        @include transition(color background-color);

        color: $color-black !important;
        background-color: $color-white;
        text-decoration: none !important;
      }

      @include respond-to('small-') {
        @include nomp();
        @include padding-all($multiplier: 0.15);
        margin: 0 auto;

        display: block;
        width: 75%;
        font-size: 1.25em;
      }
    }
  }
}