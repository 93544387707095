@import '../base/mixins';
@import 'colors';

@if import-once('theme/_setup') {
  /* theme/_setup.scss */

  body,
  html {

  }

  body {
    @include nomp();

    background-color: $color-black;
  }

  a,
  a:hover,
  a:link,
  a:visited {
    text-decoration: none;
  }
}