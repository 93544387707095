@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic;
}