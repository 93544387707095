@import '../base/typography';

$theme-default-font: 'proxima-nova', 'Arial', sans-serif !default;
$theme-heading-font: 'Ladislav', 'Arial', sans-serif !default;

$theme-text-size-small: 0.85em !default;

@if import-once('theme/_typography') {
  /* theme/_typography.scss */

  body {
    font-family: $theme-default-font;
  }
}