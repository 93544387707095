@import '../base/mixins';

@if import-once('sections/_contact-section') {
  /* sections/_contact-section.scss */

  .contact-section {
    > .background {
      background-color: $color-white;

      > .content-container {
        > .content {
          .fulltext {
            text-align: left;
            line-height: 1.75;

            @include respond-to('small+') {
              @for $i from 2 through 4 {
                &.-columns-#{$i} {
                  column-count: #{$i};
                  column-gap: $default-margin-sz;
                }
              }
            }
          }
        }
      }
    }
  }
}