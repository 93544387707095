@import '../base/colors';
@import '../base/mixins';
@import '../theme/typography';

@if import-once('components/_main-menu') {
  /* components/_main-menu.scss */

  nav.main-menu {
    @include debug-outline();

    @include respond-to('msmall-') {
      display: none !important;
    }

    &.-hidden {
      display: none;
    }

    &.-fixed {
      @include shadow($inset: false);

      position: fixed;
      top: 0;

      body.admin-bar & {
        top: 32px;
      }

      left: 0;
      width: 100%;
      z-index: 2048;
      min-height: 50px;

      background-color: $color-white;

      a.menu-item {
        min-height: 50px;
        color: $color-black;
        font-family: $theme-heading-font;
        font-weight: 600;
        font-size: 1.00em;
        text-transform: uppercase;

        &:hover {
          color: $color-white;
          background-color: $color-black;
        }
      }
    }

    a.menu-item {
      @include nomp();
      @include padding-all();
      @include transition(color background-color);

      display: block;
      font-weight: bold;
      text-align: center;
      color: $color-white;

      &:hover {
        @include transition(color background-color);

        color: $color-black;
        background-color: $color-white;
      }

      &.-sandwich {
        max-width: 50px;
        margin: 0 0 0 auto;

        &:hover {
          span.bar {
            @include transition(background-color);
            background-color: $color-white;
          }
        }
      }

      span.bar {
        @include transition(background-color);

        display: block;
        background-color: $color-black;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        margin-bottom: 3px;

        &:first-child {
          margin-top: 1px;
        }
      }
    }
  }
}