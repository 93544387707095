@import '../base/mixins';

@if import-once('sections/_project-section') {
  /* sections/_project-section.scss */

  .project-section {
    > .background {
      background-color: $color-white;

      > .content-container {
        > .content {

          img.image {
            @include margin($bottom: true, $multiplier: 2.0);

            max-width: 100%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            @include respond-to('small-') {
              @include margin($top: true, $multiplier: 2.0);

              max-width: 50%;
            }
          }

          .description {
            @include debug-outline($color: $color-yellow);

            min-height: 100%;
            text-align: left;
            line-height: 1.75;

            @include respond-to('small+') {
              column-count: 2;
              column-gap: $default-margin-sz;
            }
          }
        }
      }
    }
  }
}