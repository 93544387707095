@import '../base/colors';
@import '../base/mixins';
@import '../theme/colors';
@import '../theme/typography';

@if import-once('sections/_home-section') {
  /* sections/_home-section.scss */

  .home-section {
    height: 100vh;

    > .background {
      background-image: url('images/bg-audience.jpg');
      background-color: $color-black;

      @include respond-to('small+') {
        min-height: calc(100vh - 50px);
      }

      > .content-container {
        @include respond-to('small+') {
          min-height: calc(100vh - 50px);
        }

        > .content {
          text-align: center;

          @include respond-to('medium+') {
            vertical-align: middle;
          }
          height: 100vh;

          @include respond-to('msmall-') {
            @include respond-to('landscape') {
              height: auto;
            }
          }

          img.logo {
            max-width: 80%;

            @include respond-to('msmall-') {
              @include margin($top: true, $multiplier: -1.0);
              @include respond-to('portrait') {
                @include margin($top: true, $multiplier: -2.0);
              }
            }
          }

          .landing-about {
            @include margin($top: true, $multiplier: 3.0);
            @include margin($bottom: true, $multiplier: 8.0);
            @include padding-all();

            @include respond-to('msmall-') {
              @include respond-to('landscape') {
                @include margin($top: true, $multiplier: 1.0);
                @include margin($bottom: true, $multiplier: 2.0);
              }
            }

            color: $color-theme-grey;

            line-height: 1.75;
            font-weight: 200;
            font-size: 1.5em;
            font-family: $theme-heading-font;
            text-transform: uppercase;
            margin-left: auto;
            margin-right: auto;
            width: 60%;

            @include respond-to('small-') {
              font-size: 1.0em;
              line-height: 1.5;
            }

            @include respond-to('msmall-') {
              width: 90%;
              font-size: 0.85em;
            }
          }
        }
      }
    }
  }
}