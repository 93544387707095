@if import-once('base/_utils') {
  /* base/_utils.scss */

  @include respond-to('small-') {
    span.-next-line {
      font-size: 0;
      display: block;
      clear: both;
    }
  }
}