@import '../base/colors';

@if import-once('bootstrap-custom/_pagination') {
  /* bootstrap-custom/_pagination.scss */

  nav {
    > .pagination {
      > li {
        > a {
          color: $color-default;

          &:hover,
          &:focus {
            color: $color-default;
          }
        }

        &.active > a {
          background-color: $color-default;
          border-color: $color-default;

          &:hover,
          &:focus {
            background-color: $color-default;
            border-color: $color-default;
          }
        }
      }
    }
  }
}