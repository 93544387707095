@import '../base/colors';

@if import-once('sections/_team-section') {
  /* sections/_team-section */

  .team-section {
    > .background {
      background-color: $color-white;

      > .content-container {
        > .content {
          h2.member-name {
            @include nomp();
          }

          img.member-picture {
            @include margin($bottom: true, $multiplier: 2.0);

            max-width: 100%;
            border-radius: 50%;

            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            @include respond-to('small-') {
              @include margin($top: true, $multiplier: 2.0);
              max-width: 50%;
            }
          }

          .member-description {
            line-height: 1.75;
          }
        }
      }
    }
  }
}