@import '../base/mixins';

$form-input-height: 36px !default;

@mixin form-placeholder() {
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin form-input-default() {
  @include nomp();
  @include padding($left: true, $right: true, $multiplier: 0.50);

  color: $color-black;
  font-size: 1.0em;
  border: 0;
  border-radius: 0;
  outline: 0;

  @include respond-to('xsmall-') {
    font-size: 1.0em;
  }

  @include form-placeholder() {
    font-size: 0.75em;
  }
}

@mixin form-button() {
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  border-radius: 0;
  width: 100px;
  height: $form-input-height;
  transition: background-color $transition-duration ease, color $transition-duration ease;

  &:hover {
    transition: background-color $transition-duration ease, color $transition-duration ease;
  }
}

@mixin form-input-text() {
  @include form-input-default();

  height: $form-input-height;
}

@mixin form-input-textarea() {
  @include form-input-default();
}

@if import-once('theme/_form-mixins') {
  /* theme/_form-mixins.scss */
}