@if import-once('sections/_footer') {
  /* sections/_footer.scss */

  footer {
    @include shadow($strength: 0.25);
    @include padding-all();
    @include padding($top: true, $bottom: true, $multiplier: 2.0);

    color: lighten($color-black, 35%);
    background-color: lighten($color-black, 10%);
    font-weight: lighter;
    font-size: 0.8em;

    a {
      color: lighten($color-black, 45%);
      font-weight: bolder;

      &:hover {
        color: lighten($color-black, 65%);
        text-decoration: underline;
      }
    }

    .copyright {
      /* empty */
    }

    .developer {
      text-align: right;
    }
  }
}