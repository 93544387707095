@import '../base/colors';
@import '../base/mixins';
@import '../theme/colors';
@import '../theme/form-mixins';

@if import-once('vendor/_wpcf7') {
  /* vendor/_wpcf7.scss */

  .wpcf7 {
    @include debug-outline();
    @include nomp();
    @include padding-all($multiplier: 2.0);

    background-color: rgba($color-black, 0.05);

    form.wpcf7-form {
      @include nomp();

      p {
        @include nomp();
        @include margin($bottom: true);

        font-size: 1em;

        &:last-of-type {
          @include nomp();
        }

        label {
          font-weight: normal;

          .wpcf7-quiz-label {
            display: block;
          }
        }

        input.wpcf7-text,
        input.wpcf7-quiz {
          @include form-input-text();

          width: 100%;
          border: 1px solid $color-grey;
        }

        input.wpcf7-quiz {
          width: 50%;
        }

        input.wpcf7-submit {
          @include form-button();

          color: $color-white;
          background-color: $color-black;

          &:hover {
            color: $color-black;
            background-color: $color-white;
          }
        }

        textarea {
          @include form-input-textarea();

          width: 100%;
          border: 1px solid $color-grey;
        }
      }

      .wpcf7-not-valid-tip {
        color: $color-error;
        font-size: 0.75em;
        font-weight: bold;
      }

      .wpcf7-response-output {
        @include margin($top: true);
        @include padding-all();

        color: $color-white;
        font-weight: bold;
        text-align: center;

        &.wpcf7-validation-errors {
          background-color: $color-error;
        }

        &.wpcf7-mail-sent-ok {
          background-color: $color-success;
        }

        &.wpcf7-display-none {
          display: none;
        }
      }
    }

    .screen-reader-response {
      display: none;
    }
  }
}