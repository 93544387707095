@import '../utils/breakpoints';
@import '../base/mixins';

@if import-once('bootstrap-custom/_grid-utils') {
  /* bootstrap-custom/_grid-utils.scss */

  $respond-suffices-map: ('': false, '-xs': 'xsmall-', '-ms': 'msmall', '-sm': 'small-', '-md': 'medium-', '-lg': 'large-');

  div.row {
    &.-no-padding {
      > div[class*='col-'] {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    @each $suffix, $bkp in $respond-suffices-map {
      &.-vtop#{$suffix} {
        @include respond-to-content($bkp) {
          display: flex;
          align-items: flex-start;

          [class*='col-'] {
            display: flex;
            align-items: flex-start;
          }
        }
      }

      &.-vcenter#{$suffix} {
        @include respond-to-content($bkp) {
          display: flex;
          align-items: center;

          [class*='col-'] {
            display: flex;
            align-items: center;
          }
        }
      }

      &.-vbottom#{$suffix} {
        @include respond-to-content($bkp) {
          display: flex;
          align-items: flex-end;

          [class*='col-'] {
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }
  }

  div.row > div[class*='col-'] {
    @each $suffix, $bkp in $respond-suffices-map {
      &.-left#{$suffix} {
        @include respond-to-content($bkp) {
          justify-content: flex-start;
        }
      }
      &.-center#{$suffix} {
        @include respond-to-content($bkp) {
          justify-content: center;
        }
      }
      &.-right#{$suffix} {
        @include respond-to-content($bkp) {
          justify-content: flex-end;
        }
      }
    }

    &.-no-padding {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    &.-no-padding-left {
      padding-left: 0 !important;
    }
    &.-no-padding-right {
      padding-right: 0 !important;
    }
    @each $suffix, $bkp in $respond-suffices-map {
      &.-no-padding#{$suffix} {
        @include respond-to-content($bkp) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
      &.-no-padding-left#{$suffix} {
        @include respond-to-content($bkp) {
          padding-left: 0 !important;
        }
      }
      &.-no-padding-right#{$suffix} {
        @include respond-to-content($bkp) {
          padding-right: 0 !important;
        }
      }
    }
  }
}