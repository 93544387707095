@import '../base/colors';
@import '../base/mixins';
@import '../theme/typography';
@import '../utils/debug';

@if import-once('components/_generic-section') {
  /* components/_generic-section.scss */

  .generic-section {
    display: block;
    position: relative;
    overflow: hidden;
    height: auto !important;

    > .background {
      @include debug-outline($width: 2px, $style: solid, $color: $color-grey);
      @include shadow($strength: 0.25);

      display: block;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @include respond-to('small+') {
        min-height: 100vh;
        background-attachment: fixed;
      }
      width: 100%;

      > .content-container {
        @include debug-outline($width: 4px, $style: solid, $color: $color-green);

        display: table;
        table-layout: fixed;

        @include respond-to('small+') {
          min-height: 100vh;
        }
        width: 100%;

        > .content {
          @include debug-outline($width: 4px, $style: solid, $color: $color-blue);
          @include padding($top: true, $multiplier: 4.0);

          @include respond-to('small+') {
            @include padding($bottom: true, $multiplier: 6.0);
          }
          @include respond-to('msmall-') {
            @include padding($bottom: true, $multiplier: 2.0);
          }

          @include respond-to('small+') {
            min-height: calc(100vh - 50px);
          }

          display: table-cell;
          vertical-align: top;
          text-align: left;

          @include respond-to('small-') {
            text-align: center;
          }

          .excerpt {
            @include margin($bottom: true, $multiplier: 3.0);

            text-align: left;
            font-style: italic;
            font-size: 1.25em;
            line-height: 1.5;
          }

          h1, h2, h3, h4, h5, h6 {
            @include nomp();
            @include margin($bottom: true, $multiplier: 1.5);

            font-family: $theme-heading-font;

            @include respond-to('small-') {
              text-align: center;
            }
          }

          h1 {
            font-size: 4.0em;
            font-weight: 600;
            text-transform: uppercase;
          }

          h2 {
            font-size: 2.5em;
          }

          a {
            color: $color-black;
            font-weight: bolder;

            &:hover {
              color: inherit;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}