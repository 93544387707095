@import '../base/mixins';
@import '../base/typography';
@import '../base/colors';

$util-message-success: $color-success !default;
$util-message-notice: $color-notice !default;
$util-message-error: $color-error !default;

@if import-once('utils/_messages') {
  /* utils/_message.scss */

  .util-message {
    @include margin($top: true, $bottom: true);
    @include padding-all();
    @include typo-aa-subpixel();

    font-weight: 400;
    text-align: center;
    color: $color-white;

    strong,
    b {
      font-weight: bold;
    }

    &.-success {
      background-color: $util-message-success;
    }

    &.-notice {
      background-color: $util-message-notice;
    }

    &.-error {
      background-color: $util-message-error;
    }

    &.-admin-only {
      display: none;

      body.admin-bar & {
        display: block;
      }
    }
  }
}