/*!
    Theme Name: radiant-promotions.com
    Author: enscope, s.r.o.
    Author URI: http://www.enscope.com
    Description: Custom theme for radiant-promotions.com website. Not for public distribution.
    Version: 1.0
    License: All rights reserved.
    Tags: custom, proprietary, dark
    Text Domain: suc

    This theme was specifically designed and programmed for use by radiant-promotions.com website.
    No part of this theme can be distributed or copied without written consent of the author.
*/
/* core/_import-once.scss */
/* base/_variables.scss */
/* base/_colors.scss */
/* base/_mixins.scss */
/* utils/_breakpoint-utils.scss */
/* bootstrap-custom/_grid-ms.scss */
.col-ms-1, .col-ms-2, .col-ms-3, .col-ms-4, .col-ms-5, .col-ms-6, .col-ms-7, .col-ms-8, .col-ms-9, .col-ms-10, .col-ms-11, .col-ms-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 480px) and (max-width: 766px) {
  @media (max-width: 768px) {
    .container {
      max-width: 748px; } }
  .col-ms-1, .col-ms-2, .col-ms-3, .col-ms-4, .col-ms-5, .col-ms-6, .col-ms-7, .col-ms-8, .col-ms-9, .col-ms-10, .col-ms-11 {
    float: left; }
  .col-ms-1 {
    width: 8.33333333%; }
  .col-ms-2 {
    width: 16.66666667%; }
  .col-ms-3 {
    width: 25%; }
  .col-ms-4 {
    width: 33.33333333%; }
  .col-ms-5 {
    width: 41.66666667%; }
  .col-ms-6 {
    width: 50%; }
  .col-ms-7 {
    width: 58.33333333%; }
  .col-ms-8 {
    width: 66.66666667%; }
  .col-ms-9 {
    width: 75%; }
  .col-ms-10 {
    width: 83.33333333%; }
  .col-ms-11 {
    width: 91.66666667%; }
  .col-ms-12 {
    width: 100%; }
  .col-ms-push-1 {
    left: 8.33333333%; }
  .col-ms-push-2 {
    left: 16.66666667%; }
  .col-ms-push-3 {
    left: 25%; }
  .col-ms-push-4 {
    left: 33.33333333%; }
  .col-ms-push-5 {
    left: 41.66666667%; }
  .col-ms-push-6 {
    left: 50%; }
  .col-ms-push-7 {
    left: 58.33333333%; }
  .col-ms-push-8 {
    left: 66.66666667%; }
  .col-ms-push-9 {
    left: 75%; }
  .col-ms-push-10 {
    left: 83.33333333%; }
  .col-ms-push-11 {
    left: 91.66666667%; }
  .col-ms-pull-1 {
    right: 8.33333333%; }
  .col-ms-pull-2 {
    right: 16.66666667%; }
  .col-ms-pull-3 {
    right: 25%; }
  .col-ms-pull-4 {
    right: 33.33333333%; }
  .col-ms-pull-5 {
    right: 41.66666667%; }
  .col-ms-pull-6 {
    right: 50%; }
  .col-ms-pull-7 {
    right: 58.33333333%; }
  .col-ms-pull-8 {
    right: 66.66666667%; }
  .col-ms-pull-9 {
    right: 75%; }
  .col-ms-pull-10 {
    right: 83.33333333%; }
  .col-ms-pull-11 {
    right: 91.66666667%; }
  .col-ms-offset-1 {
    margin-left: 8.33333333%; }
  .col-ms-offset-2 {
    margin-left: 16.66666667%; }
  .col-ms-offset-3 {
    margin-left: 25%; }
  .col-ms-offset-4 {
    margin-left: 33.33333333%; }
  .col-ms-offset-5 {
    margin-left: 41.66666667%; }
  .col-ms-offset-6 {
    margin-left: 50%; }
  .col-ms-offset-7 {
    margin-left: 58.33333333%; }
  .col-ms-offset-8 {
    margin-left: 66.66666667%; }
  .col-ms-offset-9 {
    margin-left: 75%; }
  .col-ms-offset-10 {
    margin-left: 83.33333333%; }
  .col-ms-offset-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 480px) and (max-width: 766px) {
  .visible-ms {
    display: block !important; }
  table.visible-ms {
    display: table; }
  tr.visible-ms {
    display: table-row !important; }
  th.visible-ms, td.visible-ms {
    display: table-cell !important; } }

@media (min-width: 480px) and (max-width: 766px) {
  .visible-ms-block {
    display: block !important; } }

@media (min-width: 480px) and (max-width: 766px) {
  .visible-ms-flex {
    display: flex !important; } }

@media (min-width: 480px) and (max-width: 766px) {
  .visible-ms-inline {
    display: inline !important; } }

@media (min-width: 480px) and (max-width: 766px) {
  .visible-ms-inline-block {
    display: inline-block !important; } }

@media (min-width: 480px) and (max-width: 766px) {
  .hidden-ms {
    display: none !important; } }

/* utils/_breakpoints.scss */
.debug-indicators {
  display: none; }

/* utils/_debug.scss */
@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* base/_typography.scss */
body {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: black; }

/* utils/_message.scss */
.util-message {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 400;
  text-align: center;
  color: white; }
  .util-message strong, .util-message b {
    font-weight: bold; }
  .util-message.-success {
    background-color: #31ce77; }
  .util-message.-notice {
    background-color: #669cce; }
  .util-message.-error {
    background-color: #ce3e39; }
  .util-message.-admin-only {
    display: none; }
    body.admin-bar .util-message.-admin-only {
      display: block; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* bootstrap-custom/_debug.scss */
/* bootstrap-custom/_grid-utils.scss */
div.row.-no-padding > div[class*='col-'] {
  padding-left: 0 !important;
  padding-right: 0 !important; }
div.row.-vtop {
  display: flex;
  align-items: flex-start; }
  div.row.-vtop [class*='col-'] {
    display: flex;
    align-items: flex-start; }
div.row.-vcenter {
  display: flex;
  align-items: center; }
  div.row.-vcenter [class*='col-'] {
    display: flex;
    align-items: center; }
div.row.-vbottom {
  display: flex;
  align-items: flex-end; }
  div.row.-vbottom [class*='col-'] {
    display: flex;
    align-items: flex-end; }
@media only screen and (max-width: 479px) {
  div.row.-vtop-xs {
    display: flex;
    align-items: flex-start; }
    div.row.-vtop-xs [class*='col-'] {
      display: flex;
      align-items: flex-start; } }
@media only screen and (max-width: 479px) {
  div.row.-vcenter-xs {
    display: flex;
    align-items: center; }
    div.row.-vcenter-xs [class*='col-'] {
      display: flex;
      align-items: center; } }
@media only screen and (max-width: 479px) {
  div.row.-vbottom-xs {
    display: flex;
    align-items: flex-end; }
    div.row.-vbottom-xs [class*='col-'] {
      display: flex;
      align-items: flex-end; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row.-vtop-ms {
    display: flex;
    align-items: flex-start; }
    div.row.-vtop-ms [class*='col-'] {
      display: flex;
      align-items: flex-start; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row.-vcenter-ms {
    display: flex;
    align-items: center; }
    div.row.-vcenter-ms [class*='col-'] {
      display: flex;
      align-items: center; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row.-vbottom-ms {
    display: flex;
    align-items: flex-end; }
    div.row.-vbottom-ms [class*='col-'] {
      display: flex;
      align-items: flex-end; } }
@media only screen and (max-width: 991px) {
  div.row.-vtop-sm {
    display: flex;
    align-items: flex-start; }
    div.row.-vtop-sm [class*='col-'] {
      display: flex;
      align-items: flex-start; } }
@media only screen and (max-width: 991px) {
  div.row.-vcenter-sm {
    display: flex;
    align-items: center; }
    div.row.-vcenter-sm [class*='col-'] {
      display: flex;
      align-items: center; } }
@media only screen and (max-width: 991px) {
  div.row.-vbottom-sm {
    display: flex;
    align-items: flex-end; }
    div.row.-vbottom-sm [class*='col-'] {
      display: flex;
      align-items: flex-end; } }
@media only screen and (max-width: 1199px) {
  div.row.-vtop-md {
    display: flex;
    align-items: flex-start; }
    div.row.-vtop-md [class*='col-'] {
      display: flex;
      align-items: flex-start; } }
@media only screen and (max-width: 1199px) {
  div.row.-vcenter-md {
    display: flex;
    align-items: center; }
    div.row.-vcenter-md [class*='col-'] {
      display: flex;
      align-items: center; } }
@media only screen and (max-width: 1199px) {
  div.row.-vbottom-md {
    display: flex;
    align-items: flex-end; }
    div.row.-vbottom-md [class*='col-'] {
      display: flex;
      align-items: flex-end; } }
@media only screen and (max-width: 1200px) {
  div.row.-vtop-lg {
    display: flex;
    align-items: flex-start; }
    div.row.-vtop-lg [class*='col-'] {
      display: flex;
      align-items: flex-start; } }
@media only screen and (max-width: 1200px) {
  div.row.-vcenter-lg {
    display: flex;
    align-items: center; }
    div.row.-vcenter-lg [class*='col-'] {
      display: flex;
      align-items: center; } }
@media only screen and (max-width: 1200px) {
  div.row.-vbottom-lg {
    display: flex;
    align-items: flex-end; }
    div.row.-vbottom-lg [class*='col-'] {
      display: flex;
      align-items: flex-end; } }

div.row > div[class*='col-'].-left {
  justify-content: flex-start; }
div.row > div[class*='col-'].-center {
  justify-content: center; }
div.row > div[class*='col-'].-right {
  justify-content: flex-end; }
@media only screen and (max-width: 479px) {
  div.row > div[class*='col-'].-left-xs {
    justify-content: flex-start; } }
@media only screen and (max-width: 479px) {
  div.row > div[class*='col-'].-center-xs {
    justify-content: center; } }
@media only screen and (max-width: 479px) {
  div.row > div[class*='col-'].-right-xs {
    justify-content: flex-end; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row > div[class*='col-'].-left-ms {
    justify-content: flex-start; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row > div[class*='col-'].-center-ms {
    justify-content: center; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row > div[class*='col-'].-right-ms {
    justify-content: flex-end; } }
@media only screen and (max-width: 991px) {
  div.row > div[class*='col-'].-left-sm {
    justify-content: flex-start; } }
@media only screen and (max-width: 991px) {
  div.row > div[class*='col-'].-center-sm {
    justify-content: center; } }
@media only screen and (max-width: 991px) {
  div.row > div[class*='col-'].-right-sm {
    justify-content: flex-end; } }
@media only screen and (max-width: 1199px) {
  div.row > div[class*='col-'].-left-md {
    justify-content: flex-start; } }
@media only screen and (max-width: 1199px) {
  div.row > div[class*='col-'].-center-md {
    justify-content: center; } }
@media only screen and (max-width: 1199px) {
  div.row > div[class*='col-'].-right-md {
    justify-content: flex-end; } }
@media only screen and (max-width: 1200px) {
  div.row > div[class*='col-'].-left-lg {
    justify-content: flex-start; } }
@media only screen and (max-width: 1200px) {
  div.row > div[class*='col-'].-center-lg {
    justify-content: center; } }
@media only screen and (max-width: 1200px) {
  div.row > div[class*='col-'].-right-lg {
    justify-content: flex-end; } }
div.row > div[class*='col-'].-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }
div.row > div[class*='col-'].-no-padding-left {
  padding-left: 0 !important; }
div.row > div[class*='col-'].-no-padding-right {
  padding-right: 0 !important; }
div.row > div[class*='col-'].-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }
div.row > div[class*='col-'].-no-padding-left {
  padding-left: 0 !important; }
div.row > div[class*='col-'].-no-padding-right {
  padding-right: 0 !important; }
@media only screen and (max-width: 479px) {
  div.row > div[class*='col-'].-no-padding-xs {
    padding-left: 0 !important;
    padding-right: 0 !important; } }
@media only screen and (max-width: 479px) {
  div.row > div[class*='col-'].-no-padding-left-xs {
    padding-left: 0 !important; } }
@media only screen and (max-width: 479px) {
  div.row > div[class*='col-'].-no-padding-right-xs {
    padding-right: 0 !important; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row > div[class*='col-'].-no-padding-ms {
    padding-left: 0 !important;
    padding-right: 0 !important; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row > div[class*='col-'].-no-padding-left-ms {
    padding-left: 0 !important; } }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  div.row > div[class*='col-'].-no-padding-right-ms {
    padding-right: 0 !important; } }
@media only screen and (max-width: 991px) {
  div.row > div[class*='col-'].-no-padding-sm {
    padding-left: 0 !important;
    padding-right: 0 !important; } }
@media only screen and (max-width: 991px) {
  div.row > div[class*='col-'].-no-padding-left-sm {
    padding-left: 0 !important; } }
@media only screen and (max-width: 991px) {
  div.row > div[class*='col-'].-no-padding-right-sm {
    padding-right: 0 !important; } }
@media only screen and (max-width: 1199px) {
  div.row > div[class*='col-'].-no-padding-md {
    padding-left: 0 !important;
    padding-right: 0 !important; } }
@media only screen and (max-width: 1199px) {
  div.row > div[class*='col-'].-no-padding-left-md {
    padding-left: 0 !important; } }
@media only screen and (max-width: 1199px) {
  div.row > div[class*='col-'].-no-padding-right-md {
    padding-right: 0 !important; } }
@media only screen and (max-width: 1200px) {
  div.row > div[class*='col-'].-no-padding-lg {
    padding-left: 0 !important;
    padding-right: 0 !important; } }
@media only screen and (max-width: 1200px) {
  div.row > div[class*='col-'].-no-padding-left-lg {
    padding-left: 0 !important; } }
@media only screen and (max-width: 1200px) {
  div.row > div[class*='col-'].-no-padding-right-lg {
    padding-right: 0 !important; } }

/* bootstrap-custom/_pagination.scss */
nav > .pagination > li > a {
  color: black; }
  nav > .pagination > li > a:hover, nav > .pagination > li > a:focus {
    color: black; }
nav > .pagination > li.active > a {
  background-color: black;
  border-color: black; }
  nav > .pagination > li.active > a:hover, nav > .pagination > li.active > a:focus {
    background-color: black;
    border-color: black; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* base/_utils.scss */
@media only screen and (max-width: 991px) {
  span.-next-line {
    font-size: 0;
    display: block;
    clear: both; } }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* theme/_typography.scss */
body {
  font-family: 'proxima-nova', 'Arial', sans-serif; }

/* components/_main-menu.scss */
@media only screen and (max-width: 767px) {
  nav.main-menu {
    display: none !important; } }
nav.main-menu.-hidden {
  display: none; }
nav.main-menu.-fixed {
  box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2048;
  min-height: 50px;
  background-color: white; }
  body.admin-bar nav.main-menu.-fixed {
    top: 32px; }
  nav.main-menu.-fixed a.menu-item {
    min-height: 50px;
    color: black;
    font-family: 'Ladislav', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 1em;
    text-transform: uppercase; }
    nav.main-menu.-fixed a.menu-item:hover {
      color: white;
      background-color: black; }
nav.main-menu a.menu-item {
  margin: 0;
  padding: 0;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  transition: color 0.28s ease, background-color 0.28s ease;
  display: block;
  font-weight: bold;
  text-align: center;
  color: white; }
  nav.main-menu a.menu-item:hover {
    transition: color 0.28s ease, background-color 0.28s ease;
    color: black;
    background-color: white; }
  nav.main-menu a.menu-item.-sandwich {
    max-width: 50px;
    margin: 0 0 0 auto; }
    nav.main-menu a.menu-item.-sandwich:hover span.bar {
      transition: background-color 0.28s ease;
      background-color: white; }
  nav.main-menu a.menu-item span.bar {
    transition: background-color 0.28s ease;
    display: block;
    background-color: black;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    margin-bottom: 3px; }
    nav.main-menu a.menu-item span.bar:first-child {
      margin-top: 1px; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* theme/_colors.scss */
/* components/_home-menu.scss */
nav.home-menu {
  position: absolute;
  bottom: 16vh;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    @media  (orientation: landscape) {
      nav.home-menu {
        position: relative;
        bottom: auto; } } }
  nav.home-menu a.menu-item {
    margin: 0;
    padding: 0;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    transition: color 0.28s ease, background-color 0.28s ease;
    display: block;
    font-family: 'Ladislav', 'Arial', sans-serif;
    font-weight: 400 !important;
    font-size: 1.75em;
    text-transform: uppercase;
    color: #cdcdcd !important; }
    nav.home-menu a.menu-item:hover {
      transition: color 0.28s ease, background-color 0.28s ease;
      color: black !important;
      background-color: white;
      text-decoration: none !important; }
    @media only screen and (max-width: 991px) {
      nav.home-menu a.menu-item {
        margin: 0;
        padding: 0;
        padding-top: 2.25px;
        padding-right: 2.25px;
        padding-bottom: 2.25px;
        padding-left: 2.25px;
        margin: 0 auto;
        display: block;
        width: 75%;
        font-size: 1.25em; } }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

.debug-indicators {
  display: none; }

/* components/_generic-section.scss */
.generic-section {
  display: block;
  position: relative;
  overflow: hidden;
  height: auto !important; }
  .generic-section > .background {
    box-shadow: inset 0 15px 15px -15px rgba(0, 0, 0, 0.25);
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .generic-section > .background {
        min-height: 100vh;
        background-attachment: fixed; } }
    .generic-section > .background > .content-container {
      display: table;
      table-layout: fixed;
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .generic-section > .background > .content-container {
          min-height: 100vh; } }
      .generic-section > .background > .content-container > .content {
        padding-top: 60px;
        display: table-cell;
        vertical-align: top;
        text-align: left; }
        @media only screen and (min-width: 768px) {
          .generic-section > .background > .content-container > .content {
            padding-bottom: 90px; } }
        @media only screen and (max-width: 767px) {
          .generic-section > .background > .content-container > .content {
            padding-bottom: 30px; } }
        @media only screen and (min-width: 768px) {
          .generic-section > .background > .content-container > .content {
            min-height: calc(100vh - 50px); } }
        @media only screen and (max-width: 991px) {
          .generic-section > .background > .content-container > .content {
            text-align: center; } }
        .generic-section > .background > .content-container > .content .excerpt {
          margin-bottom: 45px;
          text-align: left;
          font-style: italic;
          font-size: 1.25em;
          line-height: 1.5; }
        .generic-section > .background > .content-container > .content h1, .generic-section > .background > .content-container > .content h2, .generic-section > .background > .content-container > .content h3, .generic-section > .background > .content-container > .content h4, .generic-section > .background > .content-container > .content h5, .generic-section > .background > .content-container > .content h6 {
          margin: 0;
          padding: 0;
          margin-bottom: 22.5px;
          font-family: 'Ladislav', 'Arial', sans-serif; }
          @media only screen and (max-width: 991px) {
            .generic-section > .background > .content-container > .content h1, .generic-section > .background > .content-container > .content h2, .generic-section > .background > .content-container > .content h3, .generic-section > .background > .content-container > .content h4, .generic-section > .background > .content-container > .content h5, .generic-section > .background > .content-container > .content h6 {
              text-align: center; } }
        .generic-section > .background > .content-container > .content h1 {
          font-size: 4em;
          font-weight: 600;
          text-transform: uppercase; }
        .generic-section > .background > .content-container > .content h2 {
          font-size: 2.5em; }
        .generic-section > .background > .content-container > .content a {
          color: black;
          font-weight: bolder; }
          .generic-section > .background > .content-container > .content a:hover {
            color: inherit;
            text-decoration: underline; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* sections/_home-section.scss */
.home-section {
  height: 100vh; }
  .home-section > .background {
    background-image: url('images/bg-audience.jpg');
    background-color: black; }
    @media only screen and (min-width: 768px) {
      .home-section > .background {
        min-height: calc(100vh - 50px); } }
    @media only screen and (min-width: 768px) {
      .home-section > .background > .content-container {
        min-height: calc(100vh - 50px); } }
    .home-section > .background > .content-container > .content {
      text-align: center;
      height: 100vh; }
      @media only screen and (min-width: 992px) {
        .home-section > .background > .content-container > .content {
          vertical-align: middle; } }
      @media only screen and (max-width: 767px) {
        @media  (orientation: landscape) {
          .home-section > .background > .content-container > .content {
            height: auto; } } }
      .home-section > .background > .content-container > .content img.logo {
        max-width: 80%; }
        @media only screen and (max-width: 767px) {
          .home-section > .background > .content-container > .content img.logo {
            margin-top: -15px; }
            @media  (orientation: landscape) {
              .home-section > .background > .content-container > .content img.logo {
                margin-top: -30px; } } }
      .home-section > .background > .content-container > .content .landing-about {
        margin-top: 45px;
        margin-bottom: 120px;
        padding-top: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        color: #cdcdcd;
        line-height: 1.75;
        font-weight: 200;
        font-size: 1.5em;
        font-family: 'Ladislav', 'Arial', sans-serif;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
        width: 60%; }
        @media only screen and (max-width: 767px) {
          @media  (orientation: landscape) {
            .home-section > .background > .content-container > .content .landing-about {
              margin-top: 15px;
              margin-bottom: 30px; } } }
        @media only screen and (max-width: 991px) {
          .home-section > .background > .content-container > .content .landing-about {
            font-size: 1em;
            line-height: 1.5; } }
        @media only screen and (max-width: 767px) {
          .home-section > .background > .content-container > .content .landing-about {
            width: 90%;
            font-size: 0.85em; } }

/* sections/_team-section */
.team-section > .background {
  background-color: white; }
  .team-section > .background > .content-container > .content h2.member-name {
    margin: 0;
    padding: 0; }
  .team-section > .background > .content-container > .content img.member-picture {
    margin-bottom: 30px;
    max-width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 991px) {
      .team-section > .background > .content-container > .content img.member-picture {
        margin-top: 30px;
        max-width: 50%; } }
  .team-section > .background > .content-container > .content .member-description {
    line-height: 1.75; }

/* sections/_text-section.scss */
.text-section > .background {
  background-color: white; }
  .text-section > .background > .content-container > .content .fulltext {
    text-align: left;
    line-height: 1.75; }
    @media only screen and (min-width: 768px) {
      .text-section > .background > .content-container > .content .fulltext.-columns-2 {
        column-count: 2;
        column-gap: 15px; }
      .text-section > .background > .content-container > .content .fulltext.-columns-3 {
        column-count: 3;
        column-gap: 15px; }
      .text-section > .background > .content-container > .content .fulltext.-columns-4 {
        column-count: 4;
        column-gap: 15px; } }

/* sections/_project-section.scss */
.project-section > .background {
  background-color: white; }
  .project-section > .background > .content-container > .content img.image {
    margin-bottom: 30px;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 991px) {
      .project-section > .background > .content-container > .content img.image {
        margin-top: 30px;
        max-width: 50%; } }
  .project-section > .background > .content-container > .content .description {
    min-height: 100%;
    text-align: left;
    line-height: 1.75; }
    @media only screen and (min-width: 768px) {
      .project-section > .background > .content-container > .content .description {
        column-count: 2;
        column-gap: 15px; } }

/* sections/_partners-section */
.partners-section > .background {
  background-color: white; }
  .partners-section > .background > .content-container > .content h2.member-name {
    margin: 0;
    padding: 0; }
  .partners-section > .background > .content-container > .content .partner-logo {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain; }
  .partners-section > .background > .content-container > .content .partner-name {
    /* empty */ }
  .partners-section > .background > .content-container > .content .partner-description {
    line-height: 1.75; }

/* sections/_contact-section.scss */
.contact-section > .background {
  background-color: white; }
  .contact-section > .background > .content-container > .content .fulltext {
    text-align: left;
    line-height: 1.75; }
    @media only screen and (min-width: 768px) {
      .contact-section > .background > .content-container > .content .fulltext.-columns-2 {
        column-count: 2;
        column-gap: 15px; }
      .contact-section > .background > .content-container > .content .fulltext.-columns-3 {
        column-count: 3;
        column-gap: 15px; }
      .contact-section > .background > .content-container > .content .fulltext.-columns-4 {
        column-count: 4;
        column-gap: 15px; } }

/* sections/_footer.scss */
footer {
  box-shadow: inset 0 15px 15px -15px rgba(0, 0, 0, 0.25);
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #595959;
  background-color: #1a1a1a;
  font-weight: lighter;
  font-size: 0.8em; }
  footer a {
    color: #737373;
    font-weight: bolder; }
    footer a:hover {
      color: #a6a6a6;
      text-decoration: underline; }
  footer .copyright {
    /* empty */ }
  footer .developer {
    text-align: right; }

/* theme/_setup.scss */
body {
  margin: 0;
  padding: 0;
  background-color: black; }

a, a:hover, a:link, a:visited {
  text-decoration: none; }

/* theme/_utils.scss */
.-logo-offset-top {
  margin-top: 20px; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* theme/_form-mixins.scss */
@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light.otf');
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-light-italic.otf');
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-regular.otf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-italic.otf');
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold.otf');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-semibold-italic.otf');
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Ladislav';
  src: url('vendor/ladislav/ladislav-bold-italic.otf');
  font-weight: 700;
  font-style: italic; }

/* components/_mc4wp-form.scss */
.mc4wp-form {
  margin-bottom: 30px; }
  .mc4wp-form .fields {
    margin-top: 15px; }
    .mc4wp-form .fields:after {
      content: '';
      display: table;
      clear: both; }
    @media only screen and (min-width: 1200px) {
      .mc4wp-form .fields {
        max-width: 450px; } }
    .mc4wp-form .fields > input.email {
      margin: 0;
      padding: 0;
      padding-right: 7.5px;
      padding-left: 7.5px;
      color: black;
      font-size: 1em;
      border: 0;
      border-radius: 0;
      outline: 0;
      height: 36px;
      width: calc(100% - (100px + 15px));
      float: left; }
      @media only screen and (max-width: 479px) {
        .mc4wp-form .fields > input.email {
          font-size: 1em; } }
      .mc4wp-form .fields > input.email::-webkit-input-placeholder, .mc4wp-form .fields > input.email:-moz-placeholder, .mc4wp-form .fields > input.email::-moz-placeholder, .mc4wp-form .fields > input.email:-ms-input-placeholder {
        font-size: 0.75em; }
    .mc4wp-form .fields > input.submit {
      font-weight: 600;
      text-transform: uppercase;
      border: 0;
      border-radius: 0;
      width: 100px;
      height: 36px;
      transition: background-color 0.28s ease, color 0.28s ease;
      float: right;
      color: white;
      background-color: #31ce77; }
      .mc4wp-form .fields > input.submit:hover {
        transition: background-color 0.28s ease, color 0.28s ease; }
      .mc4wp-form .fields > input.submit:hover {
        color: black;
        background-color: white; }
  .mc4wp-form .mc4wp-alert {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 400;
    text-align: center;
    color: white; }
    .mc4wp-form .mc4wp-alert strong, .mc4wp-form .mc4wp-alert b {
      font-weight: bold; }
    .mc4wp-form .mc4wp-alert.mc4wp-success {
      background-color: #31ce77; }
    .mc4wp-form .mc4wp-alert.mc4wp-notice {
      background-color: #669cce; }
    .mc4wp-form .mc4wp-alert.mc4wp-error {
      background-color: #ce3e39; }

/* vendor/_wpcf7.scss */
.wpcf7 {
  margin: 0;
  padding: 0;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.05); }
  .wpcf7 form.wpcf7-form {
    margin: 0;
    padding: 0; }
    .wpcf7 form.wpcf7-form p {
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
      font-size: 1em; }
      .wpcf7 form.wpcf7-form p:last-of-type {
        margin: 0;
        padding: 0; }
      .wpcf7 form.wpcf7-form p label {
        font-weight: normal; }
        .wpcf7 form.wpcf7-form p label .wpcf7-quiz-label {
          display: block; }
      .wpcf7 form.wpcf7-form p input.wpcf7-text, .wpcf7 form.wpcf7-form p input.wpcf7-quiz {
        margin: 0;
        padding: 0;
        padding-right: 7.5px;
        padding-left: 7.5px;
        color: black;
        font-size: 1em;
        border: 0;
        border-radius: 0;
        outline: 0;
        height: 36px;
        width: 100%;
        border: 1px solid #999; }
        @media only screen and (max-width: 479px) {
          .wpcf7 form.wpcf7-form p input.wpcf7-text, .wpcf7 form.wpcf7-form p input.wpcf7-quiz {
            font-size: 1em; } }
        .wpcf7 form.wpcf7-form p input.wpcf7-text::-webkit-input-placeholder, .wpcf7 form.wpcf7-form p input.wpcf7-text:-moz-placeholder, .wpcf7 form.wpcf7-form p input.wpcf7-text::-moz-placeholder, .wpcf7 form.wpcf7-form p input.wpcf7-text:-ms-input-placeholder, .wpcf7 form.wpcf7-form p input.wpcf7-quiz::-webkit-input-placeholder, .wpcf7 form.wpcf7-form p input.wpcf7-quiz:-moz-placeholder, .wpcf7 form.wpcf7-form p input.wpcf7-quiz::-moz-placeholder, .wpcf7 form.wpcf7-form p input.wpcf7-quiz:-ms-input-placeholder {
          font-size: 0.75em; }
      .wpcf7 form.wpcf7-form p input.wpcf7-quiz {
        width: 50%; }
      .wpcf7 form.wpcf7-form p input.wpcf7-submit {
        font-weight: 600;
        text-transform: uppercase;
        border: 0;
        border-radius: 0;
        width: 100px;
        height: 36px;
        transition: background-color 0.28s ease, color 0.28s ease;
        color: white;
        background-color: black; }
        .wpcf7 form.wpcf7-form p input.wpcf7-submit:hover {
          transition: background-color 0.28s ease, color 0.28s ease; }
        .wpcf7 form.wpcf7-form p input.wpcf7-submit:hover {
          color: black;
          background-color: white; }
      .wpcf7 form.wpcf7-form p textarea {
        margin: 0;
        padding: 0;
        padding-right: 7.5px;
        padding-left: 7.5px;
        color: black;
        font-size: 1em;
        border: 0;
        border-radius: 0;
        outline: 0;
        width: 100%;
        border: 1px solid #999; }
        @media only screen and (max-width: 479px) {
          .wpcf7 form.wpcf7-form p textarea {
            font-size: 1em; } }
        .wpcf7 form.wpcf7-form p textarea::-webkit-input-placeholder, .wpcf7 form.wpcf7-form p textarea:-moz-placeholder, .wpcf7 form.wpcf7-form p textarea::-moz-placeholder, .wpcf7 form.wpcf7-form p textarea:-ms-input-placeholder {
          font-size: 0.75em; }
    .wpcf7 form.wpcf7-form .wpcf7-not-valid-tip {
      color: #ce3e39;
      font-size: 0.75em;
      font-weight: bold; }
    .wpcf7 form.wpcf7-form .wpcf7-response-output {
      margin-top: 15px;
      padding-top: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      color: white;
      font-weight: bold;
      text-align: center; }
      .wpcf7 form.wpcf7-form .wpcf7-response-output.wpcf7-validation-errors {
        background-color: #ce3e39; }
      .wpcf7 form.wpcf7-form .wpcf7-response-output.wpcf7-mail-sent-ok {
        background-color: #31ce77; }
      .wpcf7 form.wpcf7-form .wpcf7-response-output.wpcf7-display-none {
        display: none; }
  .wpcf7 .screen-reader-response {
    display: none; }

/* vendor/_parallax-js.scss */
.parallax-window {
  background: transparent; }

