@import '../base/mixins';
@import '../base/colors';
@import '../utils/breakpoints';

$debug-mode: false !default;

@mixin debug-outline($color: $color-red, $style: dotted, $width: 1px) {
  @if $debug-mode {
    outline: $width $style $color !important;

    @each $suffix, $bkp in ('lg': 'large+', 'md': 'medium-', 'sm': 'small-', 'xs': 'xsmall-') {
      @include respond-to($bkp) {
        &[class*='-#{$suffix}-'] {
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color;
            color: $color-white;
            font-family: monospace;
            line-height: 1.0;
            content: $bkp;
            font-size: 10px;
            @include padding-all($multiplier: 0.25);
          }
        }
      }
    }
  }
}

@mixin debug-outline-indicator() {
  @if $debug-mode {
    outline: 2px solid cyan !important;

    &::before {
      position: absolute;
      font-size: 10px;
      font-family: monospace;
      padding: 4px;
      content: '-';
      background-color: cyan;
    }

    @include respond-to('xsmall') {
      outline: 2px solid green !important;

      &::before {
        content: 'xs';
        background-color: green;
      }
    }
    @include respond-to('msmall') {
      outline: 2px solid orange !important;

      &::before {
        content: 'ms';
        background-color: orange;
      }
    }
    @include respond-to('small') {
      outline: 2px solid red !important;

      &::before {
        content: 'sm';
        background-color: red;
      }
    }
    @include respond-to('medium') {
      outline: 2px solid violet !important;

      &::before {
        content: 'md';
        background-color: violet;
      }
    }
    @include respond-to('large') {
      outline: 2px solid blue !important;

      &::before {
        content: 'lg';
        background-color: blue;
      }
    }
  }
}

@if $debug-mode {
  body::after {
    @include padding-all($multiplier: 0.25);

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1024;
    font-size: 10px;
    font-family: monospace;
    font-weight: bold;
    background-color: $color-black;
    color: $color-white;

    @each $suffix, $bkp in ('xs': 'xsmall+', 'ms': 'msmall+', 'sm': 'small+', 'md': 'medium+', 'lg': 'large+') {
      @include respond-to($bkp) {
        content: $bkp;
      }
    }
  }
}

.-debug-outline {
  @include debug-outline($color: $color-white);
}

.debug-indicators {
  @if $debug-mode == false {
    display: none;
  }
}

@if import-once('utils/debug') {
  /* utils/_debug.scss */
}