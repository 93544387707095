@import 'variables';

$color-red: red;
$color-green: green;
$color-blue: blue;
$color-yellow: yellow;
$color-orange: orange;
$color-white: white;
$color-dark-grey: grey;
$color-grey: #999;
$color-light-grey: #eee;
$color-text-grey: #666;
$color-black: black;

$color-success: #31ce77 !default;
$color-notice: #669cce !default;
$color-error: #ce3e39 !default;
$color-form-submit: #31ce77 !default;

$color-default: $color-black !default;

@if import-once('base/_colors') {
  /* base/_colors.scss */
}