@import '../base/colors';

@if import-once('sections/_partners-section') {
  /* sections/_partners-section */

  .partners-section {
    > .background {
      background-color: $color-white;

      > .content-container {
        > .content {
          h2.member-name {
            @include nomp();
          }

          .partner-logo {
            @include margin($top: true, $bottom: true);
            @include debug-outline();

            width: 100%;
            height: 150px;

            background-repeat: no-repeat;
            background-position: top center;
            background-size: contain;
          }

          .partner-name {
            /* empty */
          }

          .partner-description {
            line-height: 1.75;
          }
        }
      }
    }
  }
}